import { GOOGLE_ANALYTICS_ID, APPLE_APP_ID } from "../config";

import * as React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";

import { media } from "../styles";
import { Analytics, OpenGraph, Layout, Grid, Footer } from "../components";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface Props {
  data: any;
  pageContext: { locale: string };

  meta_title: string;
  meta_description: string;
  meta_keywords: string;

  header_title: string;
  header_logo_alt: string;
  header_logo_icon: Image;
  header_navigation: {
    caption: string;
    link: string;
  }[];

  title: string;
  description: string;

  footer_privacy: string;
  footer_terms: string;
}

export const query = graphql`
  query($regex: String!) {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: $regex } }) {
      edges {
        node {
          id
          frontmatter {
            meta_title
            meta_description
            meta_keywords

            header_title
            header_logo_icon {
              childImageSharp {
                fluid {
                  src
                  srcSet
                }
              }
            }
            header_logo_alt
            header_navigation {
              caption
              link
            }

            title
            description

            footer_privacy
            footer_terms
          }
          excerpt
        }
      }
    }
  }
`;

const NotFoundPage = ({ data, pageContext: { locale } }: Props) => (
  <>
    <Analytics googleAnalyticsId={GOOGLE_ANALYTICS_ID} />
    {data.allMarkdownRemark.edges.map(({ node }: any) => (
      <div key={node.id}>
        <Helmet>
          <link rel="origin" href={`https://monsterparkbox.com/404.html`} />
          <link rel="canonical" href={`https://monsterparkbox.com/404.html`} />
          <title>{node.frontmatter.meta_title}</title>
        </Helmet>
        <OpenGraph
          title={node.frontmatter.meta_title}
          description={node.frontmatter.meta_description}
          appleAppId={APPLE_APP_ID}
        />
        <Layout
          locale={locale}
          meta_description={node.frontmatter.meta_description}
          meta_keywords={node.frontmatter.meta_keywords}
          header_title={node.frontmatter.header_title}
          header_logo_alt={node.frontmatter.header_logo_alt}
          header_logo_icon={
            node.frontmatter.header_logo_icon.childImageSharp.fluid.src
          }
          header_navigation={node.frontmatter.header_navigation}
        >
          <Grid.Layout>
            <Content>
              <Title>{node.frontmatter.title}</Title>
              <Description>{node.frontmatter.description}</Description>
            </Content>
            <Footer
              footer_privacy={node.frontmatter.footer_privacy}
              footer_terms={node.frontmatter.footer_terms}
            />
          </Grid.Layout>
        </Layout>
      </div>
    ))}
  </>
);

// Styles for styled-components
const Content = styled.div`
  height: calc(100vh - 196px);
  min-height: 7rem;
`;

const Title = styled.h1`
  padding: 2rem 0 1rem;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;

  ${media.from.laptop`font-size: 2rem;`};
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  font-size: 1rem;
`;

export default NotFoundPage;
